/*--------------------------------------------------------------
4. tp-footer
--------------------------------------------------------------*/
.tp-site-footer {
  position: relative;
  font-size: 15px;
  overflow: hidden;
  padding: 88px 0 154px 0;
  background-color: #292929;

  @media (max-width: 991px) {
    padding: 50px 0;
  }

  ul {
    list-style: none;
  }

  li {
    color: $white;
  }

  .container {
    position: relative;
  }

  .link-widget {
    overflow: hidden;

    ul {
      display: flex;
      justify-content: center;
      margin: 20px 0;
      li {
        position: relative;

        a {
          display: block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 42px;
          border: 1px solid $border-color;
          color: $light;
          font-size: 15px;
          border-radius: 50%;

          &:hover {
            background: $dark-gray;
            color: $theme-primary-color;
          }
        }
      }

      li + li {
        margin-left: 15px;
      }
    }
  }
}

.upper-footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

footer {
  background-color: #101010;
  height: 64px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

footer p {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
  font-family: "Saira";
  font-weight: 500;
}

.upper-footer-wrapper .logo-wrapper {
  text-align: left;
}

.upper-footer-wrapper .logo-wrapper a img {
  width: 202px;
  height: 30px;
}

.upper-footer-wrapper .logo-wrapper p {
  font-size: 14px;
  margin-top: 24px;
  max-width: 305px;
}

.social-media-wrapper {
  max-width: 152px;
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
  align-items: center;
}

.upper-footer-wrapper .social-media-wrapper a img {
  width: 32px;
  height: 32px;
}

.footer-navigation-wrapper {
  text-align: left;
}

.footer-navigation-wrapper ul li {
  margin-bottom: 8px;
}
.footer-navigation-wrapper ul li a {
  color: #f5f5f5 !important;
  cursor: pointer;
}
.footer-navigation-wrapper ul li a:hover {
  color: $theme-primary-color !important;
}

.footer-contact-wrapper span {
  font-size: 16px;
  font-weight: 700;
  color: #f5f5f5;
  display: block;
  margin-bottom: 24px;
}
.footer-contact-wrapper {
  text-align: left;
}

.single-contact {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.single-contact p {
  margin-bottom: 0;
  color: #f5f5f5;
  font-size: 16px;
}

.single-contact img {
  margin-right: 4px;
}

.logo-wrapper p {
  color: #f5f5f5;
}

.footer p a {
  color: #7d7d7d;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    font-family: "Saira";
    font-weight: 500;
    transition: 0.3s;
}