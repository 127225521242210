.theme-btn,
.theme-btn-s2 {
  background: #292929;
  color: #f5f5f5;
  display: inline-block;
  font-weight: 600;
  padding: 16px 32px;
  border: 0;
  text-transform: none;
  -webkit-backface-visibility: hidden;
  z-index: 1;
  position: relative;
  font-size: 16px;
  font-size: calc-rem-value(16);
  transition: all 0.3s;
  border-radius: 0;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    background: #c34343;
    color: #101010;

    &:after {
      border-color: $white;
    }
  }

  &:hover:after {
    opacity: 1;
  }

  @include media-query(991px) {
    font-size: 18px;
    font-size: calc-rem-value(18);
  }

  @include media-query(767px) {
    padding: 12px 18px;
    font-size: 14px;
  }
}

.theme-btn-s2 {
  background: $theme-primary-color;
  color: $white;

  &:after {
    border: 1px solid transparentize($dark-gray, 0.8);
  }

  &:hover,
  &:focus,
  &:active {
    background: $theme-primary-color;
    color: $white;

    &:after {
      border-color: $theme-primary-color;
    }
  }
}
