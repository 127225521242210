/* 3.2 tf-about-section */

.tf-about-section {
  border-top: 1px solid #292929;
  position: relative;
  overflow: hidden;

  .tf-about-wrap {
    .tf-about-img {
      text-align: right;
      margin-right: 123px;
      position: relative;
      max-width: 545px;
      z-index: 1;

      @media (max-width: 991px) {
        text-align: left;
      }

      img {
      }

      .tf-about-img-text {
        width: 201px;
        height: 235px;
        background: rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(15px);
        border-radius: 8px;
        margin-left: auto;
        margin-top: -200px;
        margin-right: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @media (max-width: 575px) {
          width: 140px;
          height: 180px;
          margin-top: -135px;
          margin-right: -30px;
        }

        .tf-about-icon {
          display: block;

          h3 {
            font-size: 100px;
            color: $white;

            @media (max-width: 575px) {
              font-size: 70px;
            }
          }
        }

        span {
          display: block;
          max-width: 133px;
          margin: 0 auto;
          font-weight: 700;
          font-size: 16px;
          line-height: 112.5%;
          text-align: center;
          text-transform: capitalize;
          color: $white;

          @media (max-width: 575px) {
            font-size: 14px;
          }
        }
      }
    }

    .tf-about-text {
      @media (max-width: 1399px) {
        padding-left: 30px;
      }

      @media (max-width: 991px) {
        margin-bottom: 30px;
      }

      @media (max-width: 991px) {
        padding-left: 0;
        margin-top: 40px;
      }

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 34px;
        line-height: 140%;
        letter-spacing: 0.01em;
        margin-bottom: 32px;
        color: $white;
      }

      h5 {
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 104.8%;
        max-width: 540px;
        margin-bottom: 20px;
        color: #a9a9a9;

        @media (max-width: 991px) {
          max-width: 100%;
        }

        @media (max-width: 575px) {
          font-size: 25px;
        }
      }

      p {
        overflow: hidden;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        max-width: 626px;
        color: #f5f5f5;

        @media (max-width: 991px) {
          max-width: 100%;
        }
      }

      small {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.01em;
        text-transform: none;
        font-family: $heading-font;
        color: #da3d3d;
        padding-left: 15px;
        position: relative;
        display: inline-block;
        margin-bottom: 25px;
        z-index: 1;

        &:before {
          position: absolute;
          left: 0;
          top: -7px;
          height: 39px;
          width: 39px;
          content: "";
          border: 2px solid #da3d3d;
          z-index: -11;
        }

        &:after {
          position: absolute;
          left: 34px;
          top: 3px;
          height: 20px;
          width: 20px;
          content: "";
          background: $body-bg-color;
          z-index: -1;
        }
      }

      .tf-funfact {
        display: flex;
        justify-content: space-between;
        max-width: 450px;
        margin-top: 30px;

        .tf-funfact-item {
          h3 {
            font-style: normal;
            font-weight: 400;
            font-size: 56px;
            line-height: 50px;
            color: $theme-primary-color;

            @media (max-width: 1199px) {
              font-size: 40px;
              line-height: 40px;
            }
          }

          p {
            margin-bottom: 0;
            font-weight: 300;
            font-size: 18px;
            line-height: 160%;
            font-family: $heading-font;

            @media (max-width: 575px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.visible-rotate-text {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 100%;
  text-align: center;
  opacity: 0.85;
  transform: translate(-50%, -50%);

  @media (max-width: 991px) {
    display: none;
  }

  h1 {
    font-size: 230px;
    letter-spacing: 5px;
    color: #0c0c0c;
    line-height: 200px;
    text-transform: uppercase;
    display: block;
    transform: rotate(-45deg);

    @media (max-width: 1399px) {
      font-size: 200px;
      line-height: 150px;
    }

    @media (max-width: 1199px) {
      font-size: 150px;
      line-height: 150px;
    }
  }
}

/* 3.6 tp-service-area */

.tp-service-area {
  border-top: 1px solid $border-color;
  position: relative;
  overflow: hidden;

  .tp-service-wrap {
    position: relative;
    z-index: 11;
    box-shadow: 0px 5px 30px rgba(7, 6, 56, 0.08);

    .tp-service-item {
      border-left: 1px solid $border-color;
      transition: all 0.3s;
      padding: 70px 30px;
      background: $card-bg-color;

      @media (max-width: 1399px) {
        padding: 60px 15px;
      }

      @media (max-width: 991px) {
        padding: 60px 40px;
        border-bottom: 1px solid $border-color;
      }

      @media (max-width: 767px) {
        padding: 40px 15px;
      }

      .fi {
        position: relative;

        &:after {
          position: absolute;
          left: 20px;
          top: -40px;
          width: 55px;
          height: 55px;
          background: rgba(177, 151, 119, 0.08);
          content: "";
          border-radius: 50%;
          transition: all 0.3s;
        }

        &:before {
          font-size: 50px;
          color: $theme-primary-color;
          transition: all 0.3s;
        }
      }

      h2 {
        font-weight: 400;
        font-size: 30px;
        line-height: 33px;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        transition: all 0.3s;

        @media (max-width: 1199px) {
          font-size: 24px;
          margin-bottom: 10px;
        }

        &:hover {
          color: $theme-primary-color;
        }
      }

      p {
        font-weight: 300;
        font-size: 17px;
        line-height: 160%;
        margin-bottom: 0;
        transition: all 0.3s;
      }

      .read-more {
        display: inline-block;
        width: 45px;
        height: 45px;
        line-height: 0;
        background: rgba(90, 90, 90, 0.24);
        text-align: center;
        position: relative;
        margin-top: 20px;
        border: 0;
        padding: 0;

        &:hover {
          background: $theme-primary-color;
        }

        .fi {
          position: absolute;
          transform: rotate(45deg);
          left: 11px;
          color: $white;

          &:after {
            display: none;
          }

          &:before {
            font-size: 18px;
            color: $white;
          }
        }
      }
    }

    .col {
      padding: 0;

      @media (max-width: 767px) {
        padding: 0 15px;
      }

      &:first-child {
        .tp-service-item {
          border-left: 0;
        }
      }

      &:nth-child(3) {
        @media (max-width: 991px) {
          .tp-service-item {
            border-left: 0;
          }
        }
      }

      @media (max-width: 767px) {
        .tp-service-item {
          border-left: 0;
        }
      }

      &:hover {
        .tp-service-item {
          background: #181818;

          .fi {
            &:before {
              color: $white;
            }

            &:after {
              background: rgba(255, 255, 255, 0.08);
            }
          }

          h2 {
            a {
              color: $white;

              &:hover {
                color: $theme-primary-color;
              }
            }
          }

          p {
            color: $white;
          }
        }
      }
    }
  }
}

// digital-marque-sec

.digital-marque-sec {
  background: $card-bg-color;
  padding: 40px 0;
  position: relative;
  z-index: -1;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: 20px 0;
  }

  .digital-marque {
    position: relative;
    left: -10%;
    width: 120%;
    z-index: -1;
    overflow: hidden;

    .track {
      animation: marquee 200s linear infinite;
    }

    @keyframes marquee {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(-50%);
      }
    }

    h1 {
      font-weight: 400;
      font-size: 48px;
      line-height: 102%;
      white-space: nowrap;
      will-change: transform;
      animation: marquee 20s linear infinite;
      margin-bottom: 0;
      color: $white;

      @media (max-width: 991px) {
        font-size: 30px;
      }

      @media (max-width: 575px) {
        font-size: 20px;
        animation: marquee 10s linear infinite;
      }
    }
  }
}

/* tp-project-section */

.tp-project-section {
  position: relative;
  z-index: 11;
  border-bottom: 1px solid $border-color;
  overflow: hidden;

  .project-btn {
    text-align: center;
  }

  .tp-project-wrap {
    .tp-project-item {
      margin-bottom: 40px;
      border: 1px solid $border-color;

      .tp-project-content {
        padding: 25px 45px;

        @media (max-width: 991px) {
          padding: 20px 30px;
        }

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 153.8%;
          text-transform: uppercase;
          color: rgba(255, 255, 255, 0.63);
        }

        h2 {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 103.3%;
          margin-top: 8px;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            color: $theme-primary-color;
          }

          @media (max-width: 991px) {
            font-size: 20px;
          }
        }
      }

      .tp-project-img {
        overflow: hidden;
        cursor: pointer;

        img {
          width: 100%;
          transition: all 0.3s;
          transform: scale(1);
        }
      }

      &:hover {
        .tp-project-img {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

// tp-contact-form-area

.tp-contact-form-area {
  position: relative;
  overflow: hidden;
  border-top: 1px solid $border-color;

  .tp-contact-form-wrap {
    padding: 85px 130px;
    background: $card-bg-color;
    box-shadow: 0px 15px 25px rgba(2, 4, 23, 0.08);

    @media (max-width: 991px) {
      padding: 35px 50px;
    }

    @media (max-width: 575px) {
      padding: 30px 20px;
    }

    .form-group {
      margin-bottom: 20px;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      margin-bottom: 5px;
    }

    form input,
    form select,
    form textarea {
      background: transparent;
      border: 1px solid rgba(150, 154, 166, 0.48);
      width: 100%;
      height: 56px;
      border-radius: 0px;
      box-shadow: none !important;
      padding-left: 25px;

      @include media-query(991px) {
        height: 45px;
      }

      &:focus {
        border-color: $theme-primary-color;
        background: $white;
      }
    }

    form textarea {
      height: 107px;
      padding-top: 15px;
    }

    .submit-area {
      margin-top: 32px;
      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .contact-info {
      ul {
        list-style: none;
        display: flex;
        margin-top: 40px;

        @media (max-width: 767px) {
          margin-top: 0px;
          justify-content: center;
        }

        li {
          font-weight: 500;
          font-size: 16px;
          line-height: 160%;

          @media (max-width: 575px) {
            font-size: 12px;
          }

          i {
            width: 29px;
            height: 29px;
            line-height: 29px;
            background: transparentize($theme-primary-color, 0.8);
            border-radius: 50%;
            display: inline-block;
            font-size: 14px;
            text-align: center;
            color: $theme-primary-color;
            margin-right: 5px;
            border: 1px solid $theme-primary-color;
          }

          & + li {
            margin-left: 15px;
          }
        }
      }
    }
  }
}

/* 3.8 tp-testimonial-section */

.tp-testimonial-section {
  position: relative;
  overflow: hidden;

  .tp-testimonial-wrap {
    .slick-slide {
      padding: 0 15px;
    }
  }

  .tp-testimonial-item {
    padding-bottom: 20px;
    padding: 30px 20px;
    background: $card-bg-color;

    .tp-testimonial-text {
      p {
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 154.5%;
        font-family: $heading-font;
        margin-bottom: 30px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 149.5%;
        text-transform: uppercase;
        color: $light;
        position: relative;
        padding: 20px 0;
        padding-left: 40px;
        padding-right: 100px;

        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 22px;
          height: 1px;
          background: $light;
          content: "";
        }

        &:after {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          content: "\f117";
          font-family: "flaticon";
          font-size: 55px;
        }
      }
    }
  }

  .owl-nav {
    display: none;
  }
}

/* 3.7 tp-blog-section */

.tp-blog-section {
  position: relative;
  z-index: 1;
  border-top: 1px solid $border-color;
  overflow: hidden;

  @media (max-width: 767px) {
    padding-bottom: 60px;
  }

  .tp-blog-item {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid $border-color;

    .tp-blog-img {
      overflow: hidden;
      position: relative;

      .thumb {
        position: absolute;
        left: 15px;
        top: 15px;
        padding: 7px 25px 6px;
        background: $theme-primary-color;
        color: $white;
        text-transform: uppercase;
        font-size: 14px;
      }

      img {
        width: 100%;
        -webkit-filter: grayscale(0);
        -moz-filter: grayscale(0);
        -o-filter: grayscale(0);
        -ms-filter: grayscale(0);
        filter: grayscale(0);
        transition: all 0.5s;
        transform: scale(1);
        cursor: pointer;
      }
    }

    &:hover {
      .tp-blog-img {
        img {
          transform: scale(1.2);
        }
      }
    }

    .tp-blog-content {
      padding-top: 20px;

      ul {
        list-style: none;
        display: flex;
        margin-bottom: 15px;

        li {
          color: $theme-primary-color;
          font-size: 12px;
          font-weight: 400;

          &:first-child {
            padding-right: 30px;
            position: relative;
          }

          a {
            color: $theme-primary-color;

            &:hover {
              color: $theme-primary-color;
            }
          }
        }
      }

      h2 {
        font-size: 20px;
        font-family: $heading-font;
        margin-bottom: 20px;
        line-height: 120%;
        font-weight: 600;
        margin-top: 10px;
        cursor: pointer;
        letter-spacing: -0.2px;

        @media (max-width: 1500px) {
          font-size: 22px;
          margin-bottom: 10px;
        }

        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 25px;
        }
      }

      p {
        font-size: 16px;
        color: #c4c4c4;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }
}

/* 6.4 tp-pricing-section */

.tp-pricing-section {
  border-top: 1px solid $border-color;
  position: relative;
  overflow: hidden;
  .tp-pricing-wrap {
    .tp-pricing-item {
      background: $card-bg-color;
      box-shadow: 0px 2px 16.8px 3.2px rgba(0, 38, 66, 0.08);

      @media (max-width: 991px) {
        margin-bottom: 30px;
      }

      .tp-pricing-top {
        padding: 30px;
        text-align: center;
        padding-top: 0;
        padding-bottom: 0;

        .pricing-thumb {
          display: inline-block;
          padding: 3px 30px 5px;
          background: #19181d;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          span {
            text-transform: uppercase;
            color: $white;
          }
        }

        .tp-pricing-text {
          margin-top: 30px;

          h2 {
            color: $white;
            font-size: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 15px;
            font-weight: 700;

            span {
              font-weight: 500;
              color: $text-color;
              font-size: 16px;
              text-transform: uppercase;
            }
          }

          p {
            padding-bottom: 30px;
            max-width: 282px;
            margin: 0 auto;
            border-bottom: 1px solid $border-color;
          }
        }
      }

      .tp-pricing-bottom {
        padding: 40px;
        padding-top: 35px;
        text-align: center;

        .tp-pricing-bottom-text {
          ul {
            list-style: none;

            li {
              color: $light;
              padding-bottom: 15px;
            }
          }

          a {
            font-size: 16px;
            color: $theme-primary-color;
            font-weight: 500;
            display: inline-block;
            margin-top: 20px;
            position: relative;
            padding: 10px 25px;
            border: 1px solid $theme-primary-color;

            &:hover {
              background: $theme-primary-color;
              color: $white;

              &:before {
                background-color: $theme-primary-color;
              }
            }
          }
        }
      }
    }

    .col {
      &:last-child {
        .tp-pricing-item {
          margin-bottom: 0;
        }
      }
    }
  }

  .invisible-title1 {
    display: none;
  }
}

.standup-wrapper {
  flex-direction: row-reverse;
}

.about-content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Shows styles

.shows-section {
  border-top: 1px solid #292929;
  position: relative;
}

.shows-section .content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.shows-section .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 96px;
}

.shows-section .title-wrapper h2 {
  font-size: 34px;
}

.shows-wrapper {
  width: 100%;
}

.single-show {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

@media (max-width: 1500px) {
  .shows-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.single-show:not(:first-child) {
  padding-top: 16px;
}

.single-show:not(:last-child) {
  border-bottom: 1px solid $border-color;
}

.single-show:last-child {
  margin-bottom: 56px;
}

.single-show .left {
  display: flex;
  align-items: center;
}

.single-show .left img {
  width: 202px;
  height: 169px;
  margin-right: 47px;
  object-fit: cover;
}

.btn-alt {
  background-color: $theme-primary-color;
  border: none;
  padding: 16px 32px;
  color: #101010;
  font-size: 16px;
  font-weight: 600;
  transition: 0.3s;
}

.btn-alt:hover {
  background-color: #292929;
  color: #f5f5f5;
}

.single-show .left .single-detail img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.single-detail {
  display: flex;
  align-items: center;
}

.show-details {
  display: flex;
  gap: 40px;
  margin-top: 24px;
}

.single-show .left .single-detail p {
  color: #f5f5f5;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

.single-show .left h3 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.shows-section .background-image {
  width: 725px;
  height: 900px;
  position: absolute;
  z-index: -1;
  top: 0;
  object-fit: contain;
  object-position: center;
}

// Partnership styles

.partnership-section {
  position: relative;
  border-top: 1px solid $border-color;
}

.partnership-section .background-image-2 {
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
}

.partnership-section .background-image {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  @media (max-width: 992px) {
    position: static;
  }
}

.partnership-section .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 350px;
  @media (max-width: 992px) {
    margin-bottom: 150px;
  }
}

.partnership-section .title-wrapper h2 {
  font-size: 34px;
  font-weight: 600;
  line-height: 140%;
  text-align: center;
}

.partnership-section .text-wrapper {
  max-width: 626px;
  margin-bottom: 40px;
}

.partnership-section .text-wrapper p {
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 400;
}

.partnership-section .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partnership-section .text-wrapper ul {
  margin-bottom: 16px;
  padding-left: 20px;
  list-style-image: url("../../images/partnership/listIcon.svg");
}

.partnership-section .text-wrapper ul li {
  color: #f5f5f5;
}

// Youtube styles

.youtube-section {
  position: relative;
  border-top: 1px solid #292929;
}

.youtube-section .background-image {
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 50px;
}

.youtube-section .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube-section .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube-section .title-wrapper h2 {
  text-align: center;
  margin-bottom: 72px;
}

.youtube-section .videos iframe {
  width: 100%;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-wrapper {
  position: relative;
  //   padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 1054px;
  height: 591px;
}
.player-wrapper-lower {
  position: relative;
  width: 341px;
  height: 191px;
}

.lower-video-line {
  display: flex;
  margin-top: 32px;
  gap: 16px;
  margin-bottom: 72px;
}

// Podcast styles

.socials-line {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 32px;
}

.socials-line img {
  width: 32px;
  height: 32px;
}

.socials-line p {
  color: #f5f5f5;
  margin-bottom: 0;
}

.single-podcast {
  display: flex;
  align-items: center;
  gap: 16px;
}

.single-podcast img {
  width: 198px;
  height: 111px;
}

.single-podcast p {
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}

.podcasts-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

// Blog styles

.tp-section-title h2 {
  text-transform: none;
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
}

.tp-blog-section {
  position: relative;
}

.tp-blog-section .background-image {
  position: absolute;
  width: 649px;
  height: 913px;
  right: 0;
  bottom: 0;
}

// Newsletter styles

.newsletter-input-wrapper {
  margin-top: 56px;
  max-width: 626px;
  display: flex;
}

.newsletter-input-wrapper input {
  width: 100%;
  height: 56px;
  background-color: transparent;
  border: none;
  border: 1px solid #505050;
  color: #505050;
  padding-left: 16px;
  padding-right: 16px;
  border-right: none;
}

.newsletter-input-wrapper button {
  font-size: 16px;
  border: none;
  background-color: $theme-primary-color;
  padding: 16px 32px;
  color: #101010;
  font-weight: 600;
  transition: 0.3s;
}

.newsletter-input-wrapper button:hover {
  background-color: #292929;
  color: #f5f5f5;
}

// Contact styles

.contact-section {
  border-top: 1px solid #292929;
}

.contact-section .content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section .content-wrapper .title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-section .title-wrapper h2 {
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  line-height: 140%;
}

.contact-section .content-wrapper p {
  margin-top: 48px;
  margin-bottom: 56px;
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 400;
}

.contact-section .contact-buttons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 56px;
}

.contact-buttons-wrapper button {
  background-color: $theme-primary-color;
  border: none;
  color: #101010;
  padding: 16px 0;
  width: 411px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

.contact-buttons-wrapper button:hover {
  background-color: #292929;
  color: #f5f5f5;
}

.contact-buttons-wrapper button svg {
  transition: 0.3s;
}

.contact-buttons-wrapper button:hover svg path {
  stroke: #f5f5f5;
  transition: 0.3s;
}

.contact-buttons-wrapper button svg {
  margin-right: 8px;
}

.contact-section .social-media-wrapper {
  display: flex;
  gap: 24px;
  align-items: center;
}
.social-media-wrapper svg {
  fill: #c4c4c4;
  transition: 0.3s;
}

.social-media-wrapper svg:hover {
  fill: $theme-primary-color;
}

.socials-line svg {
  fill: #c4c4c4;
  transition: 0.3s;
  cursor: pointer;
}

.socials-line svg.alt-icon {
  fill: none;
}

.socials-line svg.alt-icon path {
  stroke: #c4c4c4;
  transition: 0.3s;
}

.socials-line svg.alt-icon:hover {
  fill: none;
}

.socials-line svg.alt-icon:hover path {
  stroke: $theme-primary-color;
}

.socials-line svg:hover {
  fill: $theme-primary-color;
}

.contact-section.section-padding {
  padding-bottom: 119px;
}

.contact-buttons-wrapper svg path {
  stroke: #101010;
  transition: 0.3s;
}

@media (max-width: 1999px) {
  .show-details {
    flex-wrap: wrap;
    max-width: 350px;
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .shows-wrapper {
    padding: 0;
  }
  .single-show .left img {
    margin-right: 20px;
    width: 150px;
    height: auto;
  }

  .shows-section .title-wrapper h2 {
    text-align: center;
  }
}

@media (max-width: 860px) {
  .shows-wrapper {
    padding: 0 10px;
  }
}

@media (max-width: 1199px) {
  .tf-about-section .tf-about-wrap .tf-about-img {
    margin-right: 0;
  }

  .tf-about-section .tf-about-wrap .tf-about-img.reverted {
    margin-left: 0;
  }
}
.tf-about-img.reverted {
  text-align: right;
  position: relative;
  max-width: 545px;
  z-index: 1;
}

.standup-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standup-wrapper h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 140%;
  letter-spacing: 0.01em;
  margin-bottom: 32px;
  color: #f5f5f5;
}

.standup-wrapper p {
  overflow: hidden;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  max-width: 626px;
  color: #f5f5f5;
}

.tf-about-text {
  max-width: 626px;
}

@media (max-width: 991px) {
  .about-content-wrapper {
    flex-direction: column-reverse;
  }
  .standup-wrapper {
    flex-direction: column-reverse;
  }
}

.tf-about-section .tf-about-wrap .tf-about-text {
  padding-left: 0;
}

@media (max-width: 1399px) {
  .tf-about-section .tf-about-wrap .tf-about-img.reverted.podcasts-wrapper {
    margin-left: 40px;
  }
}
.shows-section.page {
  padding-top: 200px;
}

@media (max-width: 500px) {
  .shows-section.page {
    padding-top: 130px;
  }
}

.App {
  overflow: hidden;
}

@media (max-width: 767px) {
  .buy-btn {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .single-show {
    flex-direction: column;
    gap: 20px;
  }
  .single-show .left {
    justify-content: space-between;
  }
  .single-show .left img {
    width: 200px;
  }
  .single-show .right {
    width: 100%;
  }

  .single-show .right button {
    width: 100%;
  }

  .single-detail:last-child {
    align-items: flex-start;
  }
}

@media (max-width: 1199px) {
  .single-podcast {
    width: 100%;
  }
  .tf-about-section .tf-about-wrap .tf-about-img.reverted.podcasts-wrapper {
    margin-left: 0;
  }
  .standup-wrapper p {
    margin-bottom: 0;
  }

  .podcasts-wrapper {
    margin-top: 30px;
  }
  .tf-about-img.reverted {
    max-width: 626px;
  }
  .tf-about-section .tf-about-wrap .tf-about-img {
    max-width: 626px;
  }
  .standup-wrapper {
    flex-direction: column-reverse;
  }
}

@media (max-width: 991px) {
  .podcasts-wrapper {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .contact-buttons-wrapper button {
    width: 100%;
    padding: 16px 32px;
  }
}

.contact-section {
  position: relative;
}

.contact-section .background-image {
  position: absolute;
  z-index: -1;
  top: 40px;
}

.upper-footer-wrapper.mobile {
  display: none;
}

@media (max-width: 991px) {
  .upper-footer-wrapper {
    display: none;
  }
  .upper-footer-wrapper.mobile {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-bottom: 50px;
  }
  .footer-alternative-wrapper {
    display: flex;
    gap: 100px;
  }
}

@media (max-width: 500px) {
  .footer-alternative-wrapper {
    flex-direction: column-reverse;
    gap: 40px;
  }
}

.shows-section.page .background-image {
  right: 0;
}

.tp-blog-section.page .tp-section-title {
  margin-top: 90px;
}

@media (max-width: 768px) {
  .contact-section .background-image {
    display: none;
  }
}

.react-big-player {
  width: 1054px;
  height: 591px;
}

.react-small-player {
  width: 341px;
  height: 191px;
}

.react-videos-section {
  display: flex;
  gap: 16px;
  margin-top: 32px;
}

.subscribe-btn {
  margin-top: 72px;
}

@media (max-width: 1099px) {
  .react-big-player {
    width: 860px;
    height: 483px;
  }

  .react-small-player {
    width: 860px;
    height: 483px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 991px) {
  .react-big-player {
    width: 680px;
    height: 382px;
  }

  .react-small-player {
    width: 680px;
    height: 382px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 767px) {
  .react-big-player {
    width: 600px;
    height: 337px;
  }

  .react-small-player {
    width: 600px;
    height: 337px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 649px) {
  .react-big-player {
    width: 500px;
    height: 281px;
  }

  .react-small-player {
    width: 500px;
    height: 281px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 500px) {
  .react-big-player {
    width: 390px;
    height: 219px;
  }

  .react-small-player {
    width: 390px;
    height: 219px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

@media (max-width: 410px) {
  .react-big-player {
    width: 360px;
    height: 202px;
  }

  .react-small-player {
    width: 360px;
    height: 202px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }

  .youtube-section .title-wrapper h2 {
    margin-bottom: 50px;
  }
  .shows-section .title-wrapper h2 {
    font-size: 26px;
  }
  .partnership-section .title-wrapper h2 {
    font-size: 26px;
  }
  .partnership-section .title-wrapper {
    margin-bottom: 40px;
  }
  .tf-about-section .tf-about-wrap .tf-about-text h2 {
    font-size: 26px;
  }
  .standup-wrapper h2 {
    font-size: 26px;
  }
  .tp-section-title h2 {
    font-size: 26px;
  }
  .contact-section .title-wrapper h2 {
    font-size: 26px;
  }
}

@media (max-width: 374px) {
  .react-big-player {
    width: 330px;
    height: 185px;
  }

  .react-small-player {
    width: 330px;
    height: 185px;
  }

  .react-videos-section {
    margin-top: 32px;
    flex-direction: column;
    gap: 32px;
  }
}

.blogSinglePage {
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
  padding: 160px 0;
  &__title {
    padding-bottom: 24px;
    font-size: 52px;
    font-weight: 600;
  }
  &__subtitle {
    color: #f5f5f5;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  &__redline {
    width: 24px;
    height: 2px;
    background: #da3d3d;
  }
  &__img {
    margin: 56px 0 48px;
    width: 840px;
    height: 276px;
    object-fit: contain;
  }
  &__footer {
    padding: 88px 0 112px;
    font-size: 16px;
    display: flex;
  }
  &__share {
    font-weight: bold;
    color: #f5f5f5;
  }
  &__links {
    display: flex;
    list-style: none;
    li:last-child a {
      border: none;
    }
    &--item {
      color: #f5f5f5 !important;
      border-right: #da3d3d 1px solid !important;
      padding: 1px 16px !important;
      transition: 0.3s;
    }
    &--item:hover {
      color: $theme-primary-color !important;
    }
  }
}

@media (max-width: 840px) {
  .blogSinglePage {
    padding: 8px 32px;
    max-width: none;
  }
}

.standup-wrapper p {
  margin-bottom: 0;
}

@media (max-width: 1099px) {
  .partnership-section .title-wrapper {
    margin-bottom: 250px;
  }
}
@media (max-width: 991px) {
  .partnership-section .title-wrapper {
    margin-bottom: 50px;
  }
}

.single-show .left h3.small {
  display: none;
}

@media (max-width: 499px) {
  .single-show .left {
    flex-direction: column;
    align-items: flex-start;
  }
  .single-show .left h3 {
    display: none;
  }

  .single-show .left h3.small {
    display: block;
    margin-bottom: 20px;
    font-size: 24px;
  }
  .single-show .left img {
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .tp-site-header .menu-close {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .tp-blog-section.page .tp-section-title {
    margin-top: 0;
  }
  .tp-blog-section.section-padding.page {
    padding-top: 130px;
  }
}

@media (max-width: 991px) {
  .tp-site-header .menu-close {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 992px) {
  .showmenu {
    width: 25px;
    height: 25px;
    line-height: 32px;
  }
}
.showmenu i {
  font-size: 20px;
}

@media (max-width: 500px) {
  .single-podcast {
    flex-direction: column;
  }

  .single-podcast:last-child {
    margin-bottom: 20px;
  }
  .single-podcast img {
    width: 100%;
    height: auto;
  }
  .tf-about-section .tf-about-wrap .tf-about-img.reverted.podcasts-wrapper {
    gap: 50px;
  }
}

.lower-footer-text {
  display: flex;
}

#phone-icon {
  stroke: none;
  fill: #101010;
}

.contact-buttons-wrapper button:hover #phone-icon {
  fill: #f5f5f5;
}

.newsletter-input-wrapper input:focus {
  outline: none;
  color: #f5f5f5;
}

@media (max-width: 500px) {
  .blogSinglePage__footer {
    flex-direction: column;
    gap: 40px;
  }
  .blogSinglePage__links {
    margin-left: -16px;
  }
}

.single-show-details {
  margin-top: 160px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 112px;
}

.single-show-details .title-wrapper {
  text-align: center;
  max-width: 600px;
}

.single-show-details .title-wrapper h2 {
  font-size: 52px;
  line-height: 120%;
  font-weight: 600;
}

.single-show-details .info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 140px;
  align-items: center;
}

.info .show-details {
  max-width: none;
  margin-top: 16px;
}
.info .show-details .single-detail {
  display: flex;
  align-items: center;
}

.info .left .lower .single-detail p {
  color: #f5f5f5;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}
.info .left .single-detail img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.info .left .title {
  font-size: 34px;
  line-height: 140%;
  font-weight: 600;
}

.single-show-details .description {
  margin-top: 56px;
  max-width: 100%;
}
.single-show-details .description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: #f5f5f5;
}

.single-show-details .go-back-btn {
  margin-top: 88px;
}

@media (max-width: 991px) {
  .single-show-details .info {
    flex-direction: column;
    gap: 20px;
  }
  .single-show-details .info .right {
    width: 100%;
  }
  .single-show-details .info .right button {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .single-show-details .info {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .single-show-details .title-wrapper h2 {
    font-size: 40px;
  }
  .info .left .title {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .single-show-details .title-wrapper h2 {
    font-size: 34px;
  }
  .info .left .title {
    font-size: 22px;
  }
  .single-show-details {
    margin-top: 120px;
    margin-bottom: 60px;
  }
  .single-show-details .go-back-btn {
    margin-top: 40px;
  }
}

.shows-details img.background-image {
  position: absolute;
  top: 72px;
  left: 0;
  z-index: -1;
}

.btn-alt.btn-show-details {
  white-space: nowrap;
}

.no-show {
  margin-top: 80px;
}
