/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Saira:wght@500&display=swap");

@font-face {
  font-family: "Futura PT";
  src: url("../../fonts/FuturaPT-Demi.woff2") format("woff2"),
    url("../../fonts/FuturaPT-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT";
  src: url("../../fonts/FuturaPT-Heavy.woff2") format("woff2"),
    url("../../fonts/FuturaPT-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Futura PT";
  src: url("../../fonts/FuturaPT-Medium.woff2") format("woff2"),
    url("../../fonts/FuturaPT-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// fonts
$base-font-size: 15;
$base-font: "Inter", sans-serif;
$heading-font: "Inter";

// color
$dark-gray: #090909;
$dark-gray2: #000e28;
$body-color: #919195;
$white: #f5f5f5;
$light: #919195;
$black: #000;
$small-black: #333;
$cyan: #323232;

$theme-primary-color: #e34343;
$theme-primary-color-s2: #b88888;
$body-bg-color: #101010;
$card-bg-color: #101010;
$text-color: #676767;
$text-color2: #edb9b8;
$text-light-color: #676767;
$heading-color: $dark-gray;
$border-color: #292929;
$border-color-s2: #d8e0f1;
