/*3.1 tp-hero-slider*/

.tp-hero-section-1 {
  margin-top: 72px;
  position: relative;
  height: 700px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 1;
  overflow: hidden;

  @media (max-width: 1500px) {
    height: 700px;
  }
  @media (max-width: 991px) {
    height: 730px;
  }

  @media (max-width: 819px) {
    height: auto;
  }

  @media (max-width: 991px) {
    .right-img img {
      filter: brightness(0.6);
    }
  }

  .tp-hero-section-text {
    padding-bottom: 120px;

    @media (max-width: 575px) {
      padding-top: 60px;
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: 1500px) {
      padding-left: 70px;
    }

    @media (max-width: 1199px) {
      padding-left: 30px;
    }

    @media (max-width: 1050px) {
      padding-left: 70px;
    }

    @media (max-width: 991px) {
      padding-left: 0px;
      padding-top: 40px;
    }

    @media (max-width: 767px) {
      padding-top: 30px;
    }
  }

  .tp-hero-title h2 {
    font-size: 100px;
    font-weight: 900;
    line-height: 115px;
    margin: 10px 0 25px;
    color: $white;

    @media (max-width: 1500px) {
      font-size: 80px;
      line-height: 90px;
    }

    @media (max-width: 1399px) {
      font-size: 70px;
      line-height: 80px;
    }

    @media (max-width: 1050px) {
      font-size: 60px;
      line-height: 70px;
    }

    @media (max-width: 991px) {
      text-align: center;
    }

    @media (max-width: 767px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 575px) {
      font-size: 35px;
      margin-bottom: 0;
      line-height: 40px;
    }
  }

  .tp-hero-sub {
    margin: 30px 0;

    @media (max-width: 575px) {
      margin: 15px 0;
    }

    p {
      font-size: 20px;
      text-transform: uppercase;
      letter-spacing: 5px;
      position: relative;
      display: inline-block;
      padding-right: 120px;

      @media (max-width: 991px) {
        text-align: center;
        padding-right: 0;
        display: block;
      }

      @media (max-width: 575px) {
        font-size: 16px;
      }

      &:before {
        position: absolute;
        right: 0;
        top: 13px;
        width: 100px;
        height: 1px;
        background: #202020;
        content: "";

        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }

  .btns {
    @media (max-width: 991px) {
      text-align: center;
    }
  }

  .right-vec {
    position: absolute;
    right: 0px;
    bottom: 0;
    // top: 50%;
    // transform: translateY(-50%);
    z-index: -1;
    max-width: 1000px;

    @media (max-width: 991px) {
      top: auto;
      margin: 0 auto;
      transform: unset;
      padding-top: 30px;
    }

    .right-img {
      @media (max-width: 1500px) {
        text-align: center;
      }

      img {
        @media (max-width: 1500px) {
        }
      }
    }
  }

  .social-link {
    position: absolute;
    left: -106px;
    bottom: 150px;
    margin-bottom: 56px;
    transform: rotate(-90deg);
    text-align: center;

    @media (max-width: 1600px) {
      left: -122px;
    }
    @media (max-width: 1500px) {
      left: -132px;
    }
    @media (max-width: 1400px) {
      left: -130px;
    }
    @media (max-width: 1300px) {
      left: -142px;
    }
    @media (max-width: 991px) {
      left: -120px;
    }
    @media (max-width: 800px) {
      left: -100px;
    }
    @media (max-width: 575px) {
      left: -120px;
    }

    ul {
      display: flex;
      list-style: none;
      justify-content: center;

      li {
        a {
          position: relative;
          padding-right: 50px;
          color: $white;

          &:before {
            position: absolute;
            right: -8px;
            top: 50%;
            transform: translateX(-50%);
            width: 30px;
            height: 1px;
            background: $theme-primary-color;
            content: "";
          }

          &:hover {
            color: $theme-primary-color;
          }
        }

        &:last-child {
          a {
            padding-right: 0;

            &:before {
              display: none;
            }
          }
        }
      }
    }
  }

  .visible-text {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    z-index: -1;

    @media (max-width: 767px) {
      display: none;
    }

    h1 {
      font-size: 300px;
      letter-spacing: 5px;
      color: #0c0c0c;
      line-height: 200px;
      text-transform: uppercase;

      @media (max-width: 1700px) {
        font-size: 300px;
        line-height: 200px;
      }

      @media (max-width: 1600px) {
        font-size: 250px;
        line-height: 200px;
      }

      @media (max-width: 1399px) {
        font-size: 200px;
        line-height: 150px;
      }

      @media (max-width: 1199px) {
        font-size: 150px;
        line-height: 150px;
      }
    }
  }
}

.tp-hero-section-1 .tp-hero-title h2 {
  font-weight: 700;
  font-size: 72px;
  line-height: 120%;
  letter-spacing: -1px;
  margin: 0 0 48px 0;
}
.cartoushe.grey {
  &:after {
    background: #1e1e1e;
  }
}
.cartoushe {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.01em;
  text-transform: none;
  font-family: $heading-font;
  color: #c34343;
  padding-left: 15px;
  position: relative;
  display: inline-block;
  margin-bottom: 16px;
  z-index: 1;

  &:before {
    position: absolute;
    left: 0;
    top: -7px;
    height: 39px;
    width: 39px;
    content: "";
    border: 2px solid #c34343;
    z-index: -11;
  }

  &:after {
    position: absolute;
    left: 34px;
    top: 3px;
    height: 20px;
    width: 20px;
    content: "";
    background: $body-bg-color;
    z-index: -1;
  }
}

@media (max-width: 1550px) {
  .tp-hero-section-1 .right-vec {
    right: -100px;
  }
}

@media (max-width: 1399px) {
  .tp-hero-section-1 .right-vec {
    right: -150px;
  }
}

@media (max-width: 1200px) {
  .tp-hero-section-1 .right-vec {
    right: -200px;
  }
  .tp-hero-section-1 .social-link {
    display: none;
  }
}

@media (max-width: 819px) {
  .tp-hero-section-1 .right-vec {
    top: -70px;
  }
  .tp-hero-section-1 .tp-hero-section-text {
    padding-top: 100px;
  }
  .tp-hero-section-1 .tp-hero-title h2 {
    font-size: 52px;
  }
}

@media (max-width: 539px) {
  .tp-hero-section-1 .right-vec {
    top: initial;
    bottom: 0px;
  }
}

@media (max-width: 500px) {
  .tp-hero-section-1 .tp-hero-title h2 {
    font-size: 40px;
  }
}

@media (max-width: 459px) {
  .tp-hero-section-1 .right-vec {
    width: 620px;
  }
}
